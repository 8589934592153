import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import imagesLoaded from 'imagesloaded';

import { loadFlickity } from '../lib/async-bundles';
import gsap from "gsap";

const SLIDER_SELECTOR = '[data-slider]';
const SLIDE_SELECTOR = '[data-slide]';
const PREVBTN_SELECTOR = '[data-prevbtn]';
const NEXTBTN_SELECTOR = '[data-nextbtn]';
const SCROLLBAR_WRAP_SELECTOR = '[data-scrollbar-wrap]';
const SCROLLBAR_SELECTOR = '[data-scrollbar]';

export default el => {

    const $el = $(el);
    const $slider = $el.find(SLIDER_SELECTOR);
    const $slides = $el.find(SLIDE_SELECTOR);
    const $prevBtn = $el.find(PREVBTN_SELECTOR);
    const $nextBtn = $el.find(NEXTBTN_SELECTOR);
    const $scrollbar = $el.find(SCROLLBAR_SELECTOR);

    let slider;
    let stageW = Viewport.width;
    let Flickity = null;
    let selectedIndex = 0;

    let touchingFlickity = false;
    let touchStartCoords;

    const destroyFlickity = () => {

        if (!slider) {
            return;
        }

        slider.off('dragStart');
        slider.off('dragEnd');
        slider.off('select');
        slider.off('scroll');
        slider.off('change');
        slider.destroy();
        slider = null;

        $slider
            .attr('style', '')
            .attr('role', 'list');

        $slides.attr('style', '').off('focusin');

        $el
            .off('click')
            .removeClass('js-has-flickity js-flickity-active');
    };

    const maybeHideNextPrev = () => {
        if (!slider) {
            $el.find(`${NEXTBTN_SELECTOR},${PREVBTN_SELECTOR}`).addClass('js-hidden');
            return;
        }
        if (slider.selectedIndex > 0) {
            $prevBtn.removeClass('js-hidden');
        } else {
            $prevBtn.addClass('js-hidden');
        }
        if (slider.selectedIndex < slider.slides.length - 1) {
            $nextBtn.removeClass('js-hidden');
        } else {
            $nextBtn.addClass('js-hidden');
        }
    };

    const updateScrollbar = () => {
        if (!slider || !$scrollbar.length) {
            return;
        }
        let { x, slidesWidth } = slider;
        const progress = (x / slidesWidth) * -1;
        const scrollbarX = ($scrollbar.parent().width() - $scrollbar.width()) * progress;
        gsap.set($scrollbar.get(0), { x: scrollbarX });
    };

    const onDragStart = () => {
        document.ontouchmove = e => e.preventDefault();
        $slides.css({
            pointerEvents: 'none'
        });
    };

    const onDragEnd = () => {
        document.ontouchmove = () => true;
        requestAnimationFrame(() => {
            $slides.css({
                pointerEvents: ''
            });
        });
    };

    const onSlideChange = () => {
        selectedIndex = slider.selectedIndex;
    };

    const onSelectSlide = () => {
        maybeHideNextPrev();
    };

    const onScroll = () => {
        updateScrollbar();
    };

    const onPrevBtnClick = e => {
        e.preventDefault();
        if (!slider) {
            return;
        }
        slider.previous();
    };

    const onNextBtnClick = e => {
        e.preventDefault();
        if (!slider) {
            return;
        }
        slider.next();
    };

    const initFlickity = () => {

        if (!Flickity) {
            return;
        }

        destroyFlickity();

        $el.addClass('js-has-flickity');

        $slider
            .width($slider.width())
            .height($slider.height())
            .css({
                position: 'relative'
            });

        const config = {
            contain: true,
            dragThreshold: 15,
            cellAlign: 'left',
            prevNextButtons: false,
            pageDots: false,
            freeScroll: false,
            groupCells: true,
            freeScrollFriction: 0.045,
            selectedAttraction: 0.015,
            friction: 0.22,
            setGallerySize: false,
            resize: false,
            accessibility: false,
            initialIndex: selectedIndex
        };

        slider = new Flickity($slider.get(0), config);
        slider.on('dragStart', onDragStart);
        slider.on('dragEnd', onDragEnd);
        slider.on('select', onSelectSlide);
        slider.on('scroll', onScroll);
        slider.on('change', onSlideChange);

        $slider.get(0).removeAttribute('role');

        $slider.on('focusin', 'a', e => {
            const $slide = $(e.triggerTarget).parent(SLIDE_SELECTOR);
            const index = parseInt($slide.data('slide'), 10);
            slider.selectCell(index);
        });

        $el
            .addClass('js-flickity-active')
            .on('click', PREVBTN_SELECTOR, onPrevBtnClick)
            .on('click', NEXTBTN_SELECTOR, onNextBtnClick)
            .find('.flickity-slider')
            .attr('role', 'list');

        if ($scrollbar.length) {

            // Set the width of the scrollbar
            const numSlides = slider.slides.length;
            const scrollbarWidth = Math.round($scrollbar.parent().width() / numSlides);
            $scrollbar.width(scrollbarWidth);

            // Add the height of the scrollbar wrapper to the Flickity viewport, to sort of make the scrollbar "scrollable"
            const $viewport = $el.find('.flickity-viewport');
            const $scrollbarWrapper = $el.find(SCROLLBAR_WRAP_SELECTOR);
            if ($viewport.length && $scrollbarWrapper.length) {
                $viewport.css({
                    height: $viewport.height() + $scrollbarWrapper.height()
                });
            }

        }

        maybeHideNextPrev();
        updateScrollbar();

    };

    const maybeDoFlickity = () => {

        destroyFlickity();

        if (!$slides.length) {
            return;
        }

        const firstSlideRect = $slides.first().get(0).getBoundingClientRect();
        const lastSlideRect = $slides.last().get(0).getBoundingClientRect();
        const sliderWidth = lastSlideRect.width + (lastSlideRect.left - firstSlideRect.left);
        const availableWidth = stageW - firstSlideRect.left;
        const doFlickity = sliderWidth > availableWidth;

        if (doFlickity) {
            initFlickity();
        } else {
            destroyFlickity();
        }

    };

    const onResize = () => {
        const vw = Viewport.width;
        if (vw === stageW) {
            return;
        }
        stageW = vw;
        maybeDoFlickity();
    };

    const onDocTouchStart = e => {
        if (e.target.closest('.flickity-slider')) {
            touchingFlickity = true;
        } else {
            touchingFlickity = false;
            return;
        }

        touchStartCoords = {
            x: e.touches[0].pageX,
            y: e.touches[0].pageY
        }
    };

    const onDocTouchMove = e => {
        if (!(touchingFlickity && e.cancelable)) {
            return;
        }

        let moveVector = {
            x: e.touches[0].pageX - touchStartCoords.x,
            y: e.touches[0].pageY - touchStartCoords.y
        };

        if (Math.abs(moveVector.x) > 7) {
            e.preventDefault();
        }
    };

    const init = () => {

        Viewport.on('resize', onResize);

        maybeHideNextPrev();

        // Load Flickity async
        if (Flickity === null) {
            Flickity = false;
            loadFlickity(flickity => {
                Flickity = flickity;
                maybeDoFlickity();
            });
            return;
        }

        imagesLoaded($el.get(0), () => {
            requestAnimationFrame(() => {
                maybeDoFlickity();
            });
        });

        document.body.addEventListener('touchstart', onDocTouchStart);
        document.body.addEventListener('touchmove', onDocTouchMove, { passive: false });
    };

    const destroy = () => {
        $el.off('click');
        Viewport.off('resize', onResize);
        destroyFlickity();
        document.body.removeEventListener('touchstart', onDocTouchStart);
        document.body.removeEventListener('touchmove', onDocTouchMove);
    };

    return {
        init,
        destroy
    };

};
